<template>
  <v-form @submit.prevent="submitResponse">
    <p>a) What was studied? How?</p>
    <s-textarea v-model="inputs.q1"></s-textarea>

    <p>b) How big was the data sample?</p>
    <s-textarea v-model="inputs.q2"></s-textarea>

    <p>c) Did the researcher (you) control for any key differences?</p>
    <s-textarea v-model="inputs.q3"></s-textarea>

    <p>d) Was there a control group?</p>
    <s-textarea v-model="inputs.q4"></s-textarea>

    <p>
      e) Did you establish causality, correlation, dependence, or some other kind of relationship?
    </p>
    <s-textarea v-model="inputs.q5"></s-textarea>

    <p>f) Are the researchers (you) overstating the result?</p>
    <s-textarea v-model="inputs.q6"></s-textarea>

    <p>g) Is there any conflict of interest suggested by your affiliations or relationships?</p>
    <s-textarea v-model="inputs.q7"></s-textarea>

    <p>h) Do you seem to have an agenda?</p>
    <s-textarea v-model="inputs.q8"></s-textarea>

    <p>i) Do you acknowledge limitations and alternative explanations?</p>
    <s-textarea v-model="inputs.q9"></s-textarea>
    <p>
      j) Looking at the answers to the above questions, how much faith would you place in your
      conclusion for this experiment? If you were to REALLY have to testify in a court trial and
      your recommendations could potentially get someone arrested and imprisoned, what would you
      want to change or improve about this experimental scenario?
    </p>
    <s-textarea auto-grow v-model="inputs.q10"></s-textarea>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'OleMiss10PostLab',
  components: {STextarea, StembleLatex, ChemicalLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6: null,
        q7: null,
        q8: null,
        q9: null,
        q10: null,
      },
    };
  },
};
</script>

<style scoped></style>
